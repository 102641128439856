import React, { useState } from "react";
import { toast } from "react-toastify";
// import { useEffect } from "react";
import { Loading } from "src/components";
import { useLocation } from "react-router-dom";

export const LoadingProvider = ({ children }) => {
  const location = useLocation<any>();
  const [isLoading, setIsLoading] = useState(true);
  const [percent, setPercent] = useState(0);
  React.useEffect(() => {
    setIsLoading(true);
    for (let i = 1; i <= 100; i++) {
      setPercent(i);
    }
    const onHidePreLoading = () => {
      try {
        setPercent(100);
        const element = document.querySelector("#loading") as HTMLElement;
        element.style.opacity = "0";
        element.style.visibility = "hidden";
        setTimeout(() => {
          setIsLoading(false);
        }, 1000);
      } catch (err: any) {
        toast.error(err?.message || err);
      }
    };
    const timer1 = setTimeout(onHidePreLoading, 3000);
    return () => {
      clearTimeout(timer1);
      setPercent(0);
    };
  }, [location?.pathname]);
  return (
    <>
      {isLoading && <Loading percent={percent} />}
      {children}
    </>
  );
};
