import { lazy } from "react";
import { IRouteProps } from "src/modules";

export const route = "/risk-factors";

const TuniboxRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import("src/modules/RiskFactors")),
  name: "RiskFactors",
};

export default TuniboxRoute;
