import { lazy } from "react";
import { IRouteProps } from "src/modules";

export const route = "/";

const HomepageRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import("src/modules/Homepage")),
  name: "Home",
};

export default HomepageRoute;
