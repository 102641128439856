import React from "react";
import MainRoute from "src/modules/MainRoute";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ThemeProvider, ThemedGlobalStyle } from "src/theme";
import Header from "src/components/Header";
// import Footer from "src/components/Footer";
import Navbar from "src/components/Navbar";
import Sidebar from "src/components/Sidebar";
import Connect from "src/components/Connect";
import { LoadingState } from "src/components";
import ScrollToTop from "../components/ScrollToTop/ScrollToTop";
import { store } from "./store";
import { Provider } from "react-redux";
import { UseWalletProvider } from "use-wallet";
import { ToastContainer } from "react-toastify";
import { QueryClientProvider } from "react-query";
import { connectors } from "src/configs/Connect/Configs.rpc";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import {
  AccessTokenProvider,
  LoadingProvider,
  ChangeChainProvider,
} from "src/provider";
import "animate.css/animate.min.css";
import AuthenticateForm from "src/components/AuthenticateForm";
import ToastPopUp from "src/components/ToastPopup";
import { queryClient } from "src/services/queryClient";

let persistor = persistStore(store);

const App = () => {
  return (
    <HelmetProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider>
            <ThemedGlobalStyle />
            <QueryClientProvider client={queryClient}>
              <UseWalletProvider autoConnect={true} connectors={connectors}>
                <AccessTokenProvider>
                  <ChangeChainProvider>
                    <Router>
                      <LoadingProvider>
                        <ScrollToTop>
                          <Header />
                          <Navbar />
                          <Sidebar />
                          <ToastPopUp />
                          <LoadingState />
                          <AuthenticateForm />
                          <Connect />
                          <MainRoute />
                          {/* <Footer /> */}
                        </ScrollToTop>
                        <ToastContainer
                          position="bottom-right"
                          autoClose={5000}
                          hideProgressBar={false}
                          newestOnTop={false}
                          closeOnClick
                          pauseOnFocusLoss
                          pauseOnHover
                        />
                      </LoadingProvider>
                    </Router>
                  </ChangeChainProvider>
                </AccessTokenProvider>
              </UseWalletProvider>
            </QueryClientProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
};

export default App;
