import React from "react";
import { CircularProgress } from "@mui/material";
import { Wrapper } from "./LoadingState.styled";

export const LoadingState = () => {
  return (
    <Wrapper id="loading-state">
      <div className="overlay" />
      <div className="spinner">
        <CircularProgress />
      </div>
    </Wrapper>
  );
};
