import { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "src/app/store";
import { deleteAccount } from "src/redux";
import { selectToken, selectAddress } from "src/redux/account.selectors";
import { useCheckAccountActive } from "src/services/hooks/useWalletProvider";
import { setAccessToken } from "src/services/utils/http";

export const AccessTokenProvider = ({ children }) => {
  const isFirstTimeRender = useRef(true);
  const account = useCheckAccountActive();
  const dispatch = useAppDispatch();
  const token = useSelector(selectToken);
  const addressSigned = useSelector(selectAddress);
  useEffect(() => {
    setAccessToken(token);
  }, [token]);
  useEffect(() => {
    if (account && addressSigned?.toLowerCase() !== account.toLowerCase()) {
      dispatch(deleteAccount());
    }
    isFirstTimeRender.current = false;
  }, [account]);
  return children;
};
