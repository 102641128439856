import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { selectToken } from "src/redux/account.selectors";
import http from "src/services/utils/http";

export const handleGetProfile = async () => {
  const response = await http.get("/profile");
  return response;
};

export const handleRequestSendActivationCode = async (email: string) => {
  try {
    const response = await http.get(
      `/profile/request-link-email?email=${email}`
    );
    toast.success(`Code sent to your email ${email}`);
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

export const handleLinkCurrentAccountToEmail = async (
  email: string,
  code: string,
  password: string,
  confirmPassword
) => {
  try {
    const response = await http.put("/profile/link-email", {
      email,
      code,
      password,
      confirmPassword,
    });
    return response;
  } catch (err: any) {
    toast.error(err?.message || err);
  }
};

// Hooks of accounts
export const useGetProfile = () => {
  const token = useSelector(selectToken);
  return useQuery(["useGetProfile.name"], handleGetProfile, {
    enabled: !!token,
  });
};
