import http from "src/services/utils/http";
import { getWeb3 } from "src/services/utils/getWeb3";

export const getNonceRegistered = (address: string): any => {
  return http.get(`/auth/wallet-status?address=${address}`);
};

export const registerAndGetNonce = (address: string, chainId: number): any => {
  return http
    .post("/auth/wallet", {
      address,
      chainId,
    })
    .then((res) => res);
};

export const getSignature = async (
  publicAddress: string,
  nonce: string,
  library: any
): Promise<string> => {
  const web3 = getWeb3(library);
  const message = `${nonce}`; // require message with long text
  const encode = web3.utils.fromUtf8(message);
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    web3.eth.personal.sign(encode, publicAddress, (err, signature) => {
      if (err) return reject(err);

      return resolve(signature);
    });
  });
};

export const getAccessToken = (address: string, signature: string): any => {
  return http
    .post("auth/wallet/token", {
      address,
      signature: signature.toLowerCase(),
    })
    .then((res) => res);
};
