import React, { memo, useCallback, useState } from "react";
import { Wrapper } from "./AuthenticateForm.styled";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import {
  IStateLinkWallet,
  IStateRequestCode,
} from "./AuthenticateForm.entities";
import { LoadingButton } from "@mui/lab";
import { isValidEmail } from "src/services/helper/validate";
import {
  handleLinkCurrentAccountToEmail,
  handleRequestSendActivationCode,
} from "src/queries";
import { ArrowBack } from "@mui/icons-material";
import { useAppDispatch } from "src/app/store";
import { setToastPopup, TOAST_STATUS } from "src/redux";
import { onMoveAnimation } from "src/services/useDevelopUI";
import { queryClient } from "src/services/queryClient";

const defaultValues: IStateLinkWallet = {
  password: "",
  confirmPassword: "",
  code: "",
};

const defaultValuesRequestCode: IStateRequestCode = {
  email: "",
};

const AuthenticateForm = () => {
  const [isSentCode, setIsSentCode] = useState(false);
  const [isLoadingSendCode, setIsLoadingSendCode] = useState(false);
  const [isLoadingLinkEmail, setIsLoadingLinkEmail] = useState(false);
  const {
    register: registerRequestCode,
    trigger: triggerRequestCode,
    getValues: getRequestCodeValue,
    formState: { errors: errorsRequestCode },
    reset: resetEmail,
  } = useForm<IStateRequestCode>({
    mode: "onChange",
    defaultValues: defaultValuesRequestCode,
  });
  const {
    register,
    trigger,
    getValues,
    formState: { errors },
    reset: reset,
  } = useForm<IStateLinkWallet>({
    mode: "onChange",
    defaultValues,
  });
  const dispatch = useAppDispatch();
  const onBack = useCallback(() => setIsSentCode(false), []);

  const onClose = useCallback(() => {
    reset();
    resetEmail();
    setIsSentCode(false);
    onMoveAnimation("link-wallet-form", "moveOutOpacity");
  }, [reset, resetEmail]);
  const onRequestSendCode = useCallback(async () => {
    const isValid = await triggerRequestCode();
    if (!isValid) {
      return;
    }
    setIsLoadingSendCode(true);
    const email = getRequestCodeValue("email");
    const response = await handleRequestSendActivationCode(email);
    if (response) {
      setIsSentCode(true);
    }
    setIsLoadingSendCode(false);
  }, [trigger, getValues]);

  const onRequestLinkEmail = useCallback(async () => {
    const isValidEmail = await triggerRequestCode();
    const isValidConfirm = await trigger();
    if (!isValidEmail || !isValidConfirm) {
      return;
    }
    setIsLoadingLinkEmail(true);
    const email = getRequestCodeValue("email");
    const retain = getValues();
    const response = await handleLinkCurrentAccountToEmail(
      email,
      retain.code,
      retain.password,
      retain.confirmPassword
    );
    if (response) {
      onClose();
      queryClient.refetchQueries(["useGetProfile.name"]);
      dispatch(
        setToastPopup({
          title: "Success in Linking Wallet to Email",
          description: `Your wallet is now linked to your email address at ${email}.`,
          status: TOAST_STATUS.SUCCESS,
        })
      );
    }
    setIsLoadingLinkEmail(false);
  }, []);
  return (
    <Wrapper id="link-wallet-form">
      <div onClick={onClose} className="overlay" />
      <div className="form">
        <div className="container-icon">
          <IconButton
            onClick={onBack}
            className={`icon ${!isSentCode && "invisible"}`}
          >
            <ArrowBack />
          </IconButton>
          <IconButton className="icon">
            <CloseIcon />
          </IconButton>
        </div>
        <span className="title">You are almost there!</span>
        <span className="subtitle">
          Connect your Tuniver account to continue
        </span>
        <input
          placeholder="Email Address"
          disabled={isSentCode}
          className={`input ${errorsRequestCode.email && "error"}`}
          {...registerRequestCode("email", {
            required: true,
            validate: (email) => isValidEmail(email),
          })}
          type="text"
        />
        {isSentCode && (
          <>
            <input
              placeholder="Password"
              className={`input ${errors.password && "error"}`}
              {...register("password", {
                required: true,
              })}
              type="password"
            />
            <input
              className={`input ${errors.confirmPassword && "error"}`}
              placeholder="Confirm Password"
              {...register("confirmPassword", {
                required: true,
                validate: (value) => value === getValues("password"),
              })}
              type="password"
            />
            <input
              placeholder="Code"
              className={`input ${errors.code && "error"}`}
              {...register("code", {
                required: true,
              })}
              type="text"
            />
          </>
        )}
        {!isSentCode && (
          <LoadingButton
            onClick={onRequestSendCode}
            className="btn-loading-primary"
            loading={isLoadingSendCode}
          >
            Request Send Code
          </LoadingButton>
        )}
        {isSentCode && (
          <LoadingButton
            loading={isLoadingLinkEmail}
            onClick={onRequestLinkEmail}
            className="btn-loading-primary"
          >
            Request Link Email To Wallet
          </LoadingButton>
        )}
        {isSentCode && (
          <LoadingButton
            loading={isLoadingSendCode}
            onClick={onRequestSendCode}
            className="btn-loading-secondary"
          >
            Resend Code
          </LoadingButton>
        )}
      </div>
    </Wrapper>
  );
};

export default memo(AuthenticateForm);
