import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  color: white;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  padding: 0 10px;
  .success {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    &.left {
      left: 0;
    }
    &.right {
      right: 0;
    }
  }
  .popup {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 532px;
    background: rgba(53, 52, 65, 0.5);
    backdrop-filter: blur(50px);
    padding: 56px 32px;
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          padding: 25px 25px;
    `}
    .icon {
      align-self: flex-end;
      color: white;
    }

    .title {
      font-family: "Clash Display";
      font-weight: 600;
      font-size: 32px;
      margin: 16px 0;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
             font-size: 28px;
    `}
    }
    .subtitle {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 18px;
      color: #9ba5b1;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
             font-size: 16px;
    `}
    }
  }
`;
