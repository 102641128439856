import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  visibility: hidden;
  .spinner {
    width: 60px;
    height: 60px;
    background-color: white;
    display: flex;
    border-radius: 20px;
    align-items: center;
    justify-content: center;
  }
`;
