import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  .app-header {
    transition: all 0.3s;
    z-index: 999;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 10px 0px;
    background: rgba(11, 12, 14, 0.1);
    backdrop-filter: blur(7px);
    display: flex;
    justify-content: center;
    align-items: center;
    &.header-down {
      opacity: 0;
    }
    &.header-up {
      opacity: 1;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .btn-icon {
        color: white;
        margin: 0px 10px;
        svg {
          font-size: 32px;
        }
      }
      .wallet-container {
        position: relative;
        .wallet-icon {
          width: 30px;
        }
        .wallet-status {
          position: absolute;
          animation: moveOutOpacity 0.3s;
          animation-fill-mode: forwards;
          color: white;
          width: 450px;
          top: 47px;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          margin-top: 10px;
          padding: 20px 24px;
          border-radius: 20px 20px 20px 20px;
          right: 0px;

          background: rgba(53, 52, 65, 0.5);
          z-index: 10000;
          backdrop-filter: blur(7px);
          ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
         right: -50px;
         max-width: 90vw;
    `}
          .header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            flex-wrap: wrap;
            .wallet-info {
              display: flex;
              align-items: center;
              gap: 5px;
            }
            .wallet-info-item {
              display: flex;
              align-items: center;
              gap: 5px;
              > span {
                font-family: "Clash Display";
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                color: #ffffff;
              }
              > img {
                height: 16px;
              }
            }
          }
          .wallet-address {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            margin-bottom: 18px;
            img {
              width: 24px;
              margin-right: 10px;
            }
            .address {
              display: flex;
              flex-direction: column;
              span {
                font-family: "Clash Display";
                font-weight: 500;
                font-size: 16px;
              }
              .account {
                color: rgba(155, 165, 177, 1);
                margin-top: 5px;
              }
            }
          }
          .title {
            font-family: "Clash Display";
            font-weight: 600;
            font-size: 24px;
            ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
         font-size: 22px; 
    `}
          }
          .subtitle {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            display: block;
            margin: 18px 0px;
            a {
              color: rgba(194, 115, 242, 1);
              display: inline;
              font-family: "Poppins";
            }
          }
          .btn-sign {
            width: 100%;
            /* background-color: black; */
            border: 1px solid #c273f2;
            color: #c273f2;
            padding: 12px 20px;
            font-family: "Clash Display";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            border-radius: 20px;

            text-align: center;
            transition: all 0.3s;
            position: relative;
            cursor: pointer;
            &:hover {
              transform: scale(1.02);
            }
            &:active {
              transform: scale(1);
            }
          }
          .btn {
            margin-bottom: 10px;
            padding: 12px 20px;
            width: 100%;
            text-align: center;
            background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%);
            border-radius: 20px;
            font-family: "Clash Display";
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            transition: all 0.2s;
            cursor: pointer;
            position: relative;
            &:hover {
              transform: scale(1.02);
            }
            &:active {
              transform: scale(1);
            }
          }
        }
      }
      .account {
        position: relative;
        color: white;
        cursor: pointer;
        &:hover .title {
          background: rgba(53, 52, 65, 0.5);
          svg {
            transform: rotate(180deg);
          }
        }
        .title {
          display: flex;
          padding: 8px 10px;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          transition: all 0.3s;
          border-radius: 40px;
          ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
           padding: 0px;
    `}
          span {
            font-family: "Clash Display";
            font-weight: 500;
            font-size: 18px;
            margin-right: 5px;
            max-width: 183px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          display: none;
    `}
          }
          svg {
            transition: all 0.3s;
          }
          .avatar {
            border-radius: 50%;
            overflow: hidden;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          margin-right: 3px;
    `}
            background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%),
              linear-gradient(
                334.7deg,
                #7fc1dd 15.23%,
                #7d73f2 17.51%,
                #000000 24.08%
              );
            img {
              width: 38px;
              background-color: black;
              border-radius: 50%;
            }
          }
        }
        &:hover .menu-container {
          opacity: 1;
          visibility: visible;
        }
        .menu-container {
          position: absolute;
          padding-top: 5px;
          top: 52px;
          width: 100%;
          overflow: hidden;
          opacity: 0;
          visibility: hidden;
          transition: all 0.3s;
          ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
            width: 185px;
    `}
          .menu {
            width: 100%;
            background: rgba(53, 52, 65, 0.5);
            border-radius: 20px;
            padding: 16px;
            a {
              color: white;
              text-decoration: none;
              padding: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              font-family: "Clash Display";
              font-weight: 600;
              font-size: 16px;
              border-radius: 10px;
              transition: all 0.3s;
              svg {
                margin-right: 12px;
              }
              &:hover {
                background: rgba(155, 165, 177, 0.15);
              }
            }
          }
        }
      }
      .icon-rights {
        min-width: 33%;
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        align-items: center;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
          gap: 4px;
    `}
        > img {
          width: 30px;
          cursor: pointer;
        }
        .btn-rights {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 14px 20px;
          gap: 10px;

          width: 156px;
          height: 53px;

          /* Gray/15 */

          background: rgba(155, 165, 177, 0.15);
          border-radius: 60px;
          font-family: "Clash Display";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          text-align: center;

          /* White/ 1 */

          color: #ffffff;
          ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToSmall`
          display: none;
    `}
        }
      }
    }
    .btn-center {
      cursor: pointer;
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height */

      text-align: center;
      text-decoration-line: underline;

      color: #ffffff;
      white-space: nowrap;
      margin-right: 55px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToMedium`
          display: none;
    `}
    }
    .icon-lefts {
      width: 33%;
      cursor: pointer;

      > img {
        width: 40px;
      }
    }
  }
`;
