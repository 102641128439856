import { lazy } from "react";
import { IRouteProps } from "src/modules";

export const route = "/authenticate";

const AuthenticateRoute: IRouteProps = {
  path: route,
  exact: true,
  component: lazy(() => import("src/modules/Authenticate/Authenticate")),
  name: "Authenticate",
};

export default AuthenticateRoute;
