import React from "react";
import { Wrapper } from "./Connect.styled";
import withConnnect from "src/hoc/withWallet";
import CloseIcon from "@mui/icons-material/Close";

const Connect = ({ connect, onCloseConnectModal }: any) => {
  const [isRead, setIsread] = React.useState<boolean>(false);

  return (
    <Wrapper className="connect-wallet" id="connect-wallet-overlay">
      <div className="overlay" onClick={onCloseConnectModal} />
      <div className="wrraper">
        <div
          className="close-icon"
          onClick={() => {
            onCloseConnectModal();
            setIsread(false);
          }}
        >
          <CloseIcon />
        </div>
        <div className="title">Connect your wallet</div>
        <div className="subtitle">
          Select the network and wallet you want to connect below
        </div>
        <div className="subtitles">
          Accept <span>Term of Service</span> and <span>Privacy Policy</span>
        </div>
        <div className="checkbox-read">
          <label className="filter-input">
            <input
              id="accept"
              type="checkbox"
              checked={isRead}
              onChange={() => {
                setIsread(!isRead);
              }}
            />
            <span className=""></span>
          </label>
          <label htmlFor="accept">I read & accept</label>
        </div>
        {/* <div className="titles">Choose Network</div>
        <div className={`network-list ${isRead ? "active" : ""}`}>
          <div className="item">
            <img
              src={require("../../assets/connect/ethe.png").default}
              alt=""
            />
            <span>Ethereum</span>
          </div>
          <div className="item">
            <img
              src={require("../../assets/connect/binance.png").default}
              alt=""
            />
            <span>Binance Chain</span>
          </div>
          <div className="item">
            <img
              src={require("../../assets/connect/poly.png").default}
              alt=""
            />
            <span>Polygon</span>
          </div>
        </div> */}
        <div className="titles">Choose Wallet</div>
        <div className={`network-list ${isRead ? "active" : ""}`}>
          <div onClick={() => connect("injected")} className="item">
            <img
              src={require("../../assets/connect/metamask.png").default}
              alt=""
            />
            <span>Metamask</span>
          </div>
          <div onClick={() => connect("walletlink")} className="item">
            <img
              src={require("../../assets/connect/coin.png").default}
              alt=""
            />
            <span>Coinbase</span>
          </div>
        </div>
        <div
          onClick={() => connect("walletconnect")}
          className={`network-list bott ${isRead ? "active" : ""}`}
        >
          <div className="item">
            <img
              src={require("../../assets/connect/wallet.png").default}
              alt=""
            />
            <span>Wallet Connect</span>
          </div>
          <div className="item">
            <img
              src={require("../../assets/connect/binance2.png").default}
              alt=""
            />
            <span>Binance</span>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default withConnnect(Connect);
