import { toast } from "react-toastify";
import { useWriteTransaction } from "src/services/hooks/useWriteTransaction";
import CollabABI from "src/constants/abis/TuniverCollaborator.json";
import ERC20ABI from "src/constants/abis/TokenAppr.json";
import http from "src/services/utils/http";
import { ENVS } from "src/configs/Configs.env";
import { useEffect, useState } from "react";
import { tokenSupported } from "src/constants/tokens";
import { APP_COLLECTION_ID } from "src/constants/app-config";
import { ethers } from "ethers";
import { useQuery } from "react-query";
import { useCheckAccountActive } from "src/services/hooks/useWalletProvider";
// ENVS.REACT_APP_BASE_API +
export const requestApproveBuyBox = async (
  collectionId: number,
  amount: number,
  tokenAddress: string
): Promise<any> => {
  const response = await http.get(
    `/v2/marketplace/tunibox/approve?collectionId=${collectionId}&amount=${amount}&tokenAddress=${tokenAddress}`
  );
  return response;
};

export const useGetBoxCampaing = () => {
  return useQuery(
    ["useGetBoxCampaing"],
    async () => {
      try {
        const data: any = await http.get(`/v2/marketplace/tunibox/campaign`);
        return data;
      } catch (err: any) {
        return null;
      }
    },
    {
      refetchInterval: 3000,
    }
  );
};

export const useGetBoxDefaultPrice = () => {
  return useQuery(
    ["useGetBoxDefaultPrice"],
    async () => {
      try {
        const data: any = await http.get(
          `/v2/marketplace/tunibox/price?collectionId=${APP_COLLECTION_ID}&amount=1&tokenAddress=${tokenSupported[2].address}`
        );
        return ethers.utils.formatEther(data.totalPrice);
      } catch (err: any) {
        return null;
      }
    },
    {
      refetchInterval: 10000,
    }
  );
};

export const useGetWalletInfo = (address: string) => {
  return useQuery(
    ["useGetWalletInfo"],
    async () => {
      try {
        const data: any = await http.get(
          `/v2/inventory/tunibox/${address}?take=500&pageIndex=0`
        );
        return data;
      } catch (err: any) {
        return null;
      }
    },
    {
      refetchInterval: 10000,
      enabled: !!address,
    }
  );
};

export const useRequestApproveAmount = () => {
  const { sendTransaction } = useWriteTransaction();
  return async (contractAddr: string, amount: number) => {
    try {
      const response = await sendTransaction(
        contractAddr,
        ERC20ABI,
        "approve",
        "0",
        ethers.utils.getAddress(ENVS.REACT_APP_BUY_BOX_ADDRESS),
        amount
      );
      return response;
    } catch (err: any) {
      toast.error(err?.message || err);
    }
  };
};

export const useGetTotalPriceBox = () => {
  const [amount, setAmount] = useState(1);
  const [tokenAddress, setTokenAddress] = useState(tokenSupported[0].address);
  const [totalPrice, setTotalPrice] = useState<any>(null);
  useEffect(() => {
    const getTotalPrice = async () => {
      const response: any = await http.get(
        `/v2/marketplace/tunibox/price?collectionId=${APP_COLLECTION_ID}&amount=${
          amount ? amount : "1"
        }&tokenAddress=${
          tokenAddress ? tokenAddress : tokenSupported[0].address
        }`
      );
      setTotalPrice(response);
    };
    // console.log("total", totalPrice);
    getTotalPrice();
  }, [amount, setTotalPrice, tokenAddress]);

  return { amount, setAmount, totalPrice, tokenAddress, setTokenAddress };
};

export const useCheckWhiteList = () => {
  const account = useCheckAccountActive();
  return async () => {
    try {
      const response: any = await http.get(
        `/v2/marketplace/private-sale/whitelist?address=${account}&resource=Tunibox`
      );
      if (response) {
        return response?.inWhitelist;
      }
      return false;
    } catch {
      return null;
    }
  };
};

export const useRequestBuyBox = () => {
  const { sendTransaction } = useWriteTransaction();
  return async (amount: number, tokenAddress: string) => {
    try {
      const { buyer, signature } = await requestApproveBuyBox(
        APP_COLLECTION_ID,
        amount,
        tokenAddress
      );
      const isNativeToken = tokenAddress === tokenSupported[0].address;
      const formatedBuyer = {
        ...buyer,
        totalPrice: ethers.utils
          .parseEther(ethers.utils.formatEther(buyer.totalPrice))
          .toHexString(),
      };
      const response = await sendTransaction(
        ENVS.REACT_APP_BUY_BOX_ADDRESS,
        CollabABI,
        "buy",
        isNativeToken
          ? ethers.utils.formatEther(formatedBuyer.totalPrice)
          : "0",
        formatedBuyer,
        signature
      );
      return response;
    } catch (err: any) {
      toast.error(err?.message || err);
    }
  };
};
