export const getWalletDeepLink = (connectorName: string) => {
  const dAppUrl = window?.location?.href;
  if (!dAppUrl) {
    return "";
  }
  const deepLinks = {
    injected: `https://metamask.app.link/dapp/${dAppUrl}`,
    walletlink: `https://go.cb-w.com/dapp?cb_url=${dAppUrl}`,
  };

  return deepLinks[connectorName];
};
