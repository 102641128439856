import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  /* color: #e7e1d9; */
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > div.overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  > div.wrraper {
    max-height: 90vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
    z-index: 2;
    background: rgba(53, 52, 65, 0.8);
    /* Blur BG 50% */
    backdrop-filter: blur(50px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 20px;
    width: 100%;
    max-width: 710px;
    padding: 56px 48px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    position: relative;
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        padding: 32px 16px;  
        `}
    .close-icon {
      position: absolute;
      top: 56px;
      right: 48px;
      color: #ffffff;
      cursor: pointer;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        top: 32px;
        right: 16px; 
        `}
    }
    .title {
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 39px;
      color: #ffffff;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 30px;
        `}
    }
    .subtitle {
      margin-top: 13px;
      font-family: "Poppins";
      font-weight: 400;
      font-size: 18px;
      color: #9ba5b1;

      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        `}
    }
    .subtitles {
      margin-top: 50px;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      color: #ffffff;

      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        margin-top: 24px;
        `}
      > span {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        color: #c273f2;
      }
    }
    .titles {
      margin-top: 25px;
      font-family: "Clash Display";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      /* identical to box height */
      color: #ffffff;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        margin-top: 24px;
        font-family: "Clash Display";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
        `}
    }
    .network-list {
      margin-top: 20px;
      &.bott {
        margin-top: 20px;
      }
      display: flex;
      gap: 20px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        margin-top: 16px;
        gap: 8px;
        flex-direction: column;
        &.bott {
          margin-top: 8px;
        }
        `}
      .item {
        transition: 0.3s all;
        width: 100%;
        opacity: 0.5;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 20px;
        background: rgba(155, 165, 177, 0.15);
        border-radius: 20px;
        cursor: pointer;
        pointer-events: none;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
            gap: 10px;
            flex-direction: row;
            justify-content: center;
            padding-top: 8px;
            padding-bottom: 8px;
            border-radius: 64px;
        `}
        > img {
          width: 32px;
        }
        > span {
          margin-top: 8px;
          font-family: "Clash Display";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 32px;
          /* identical to box height, or 160% */

          text-align: center;

          /* White/ 1 */

          color: #ffffff;
        }
      }
      &.active .item {
        opacity: 1;
        pointer-events: all;
      }
    }
    .checkbox-read {
      margin-top: 4px;
      display: flex;
      align-items: center;
      gap: 14px;
      .filter-input {
        display: block;
        position: relative;
        padding-left: 22px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        > input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
          &:checked {
            ~ span {
              background-color: #c273f2;
              &::after {
                display: block;
              }
            }
          }
        }
        > span {
          position: absolute;
          top: 50%;
          left: 0;
          height: 20px;
          width: 20px;
          border-radius: 6px;
          background-color: #c273f2;
          /* border: solid 1px #51555a; */
          transform: translateY(-50%);
          &::after {
            content: "";
            left: 7px;
            top: 3px;
            width: 4px;
            height: 8px;
            border: solid #5b4b37;
            border-width: 0 2px 2px 0;
            -webkit-transform: rotate(45deg), translate(-50%, -50%);
            -ms-transform: rotate(45deg), translate(-50%, -50%);
            transform: rotate(45deg);
            position: absolute;
            display: none;
          }
        }
      }
      > label {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        /* identical to box height */

        /* White/ 1 */

        color: #ffffff;
        ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
        
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        `}
      }
    }
  }
`;
