import React from "react";
import { toast } from "react-toastify";
import { Wrapper } from "./Sidebar.styled";
import {
  FacebookShareButton,
  TwitterShareButton,
  TelegramShareButton,
} from "react-share";

const Sidebar = () => {
  return (
    <Wrapper id="sidebar">
      <div className="container">
        <div className="app-sidebar">
          <div className="sidebar-top">
            <div className="title">SHARE</div>
            <div className="social">
              <FacebookShareButton url={window.location.href} className="">
                <img
                  src={
                    require("../../assets/navbar/Facebook - Negative.png")
                      .default
                  }
                  alt=""
                />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href} className="">
                <img
                  src={
                    require("../../assets/navbar/Twitter - Negative.png")
                      .default
                  }
                  alt=""
                />
              </TwitterShareButton>
              <TelegramShareButton url={window.location.href} className="">
                <img
                  src={require("../../assets/navbar/Subtract.png").default}
                  alt=""
                />
              </TelegramShareButton>

              <img
                onClick={() => {
                  toast.info("Discord is not available now!");
                }}
                src={require("../../assets/navbar/Vector (1).png").default}
                alt=""
              />
            </div>
            <div className="progress">
              {
                <img
                  src={require("../../assets/sidebar/progress.gif").default}
                  alt=""
                />
              }
            </div>
          </div>
          <div className="sidebar-bot">
            <div className="title" id="title-bot-id">
              Scroll down to discover
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Sidebar;
