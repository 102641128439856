import React, { FunctionComponent, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useGetProfile } from "src/queries";
import { deleteAccount } from "src/redux";
import { selectToken } from "src/redux/account.selectors";

export const withAccount =
  (WrappedComponent: FunctionComponent) => (props: any) => {
    const dispatch = useDispatch();
    const profile = useGetProfile();
    const token = useSelector(selectToken);
    const handleLogout = useCallback(() => {
      dispatch(deleteAccount());
    }, [dispatch, deleteAccount]);
    return (
      <WrappedComponent
        {...{
          ...props,
          profile,
          token,
          handleLogout,
        }}
      />
    );
  };
