import { configureStore } from "@reduxjs/toolkit";
import { ThemeReducer } from "src/theme/Theme.reducer";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import { AccountReducer, ToastPopupReducer } from "src/redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { useDispatch } from "react-redux";
import { ENVS } from "src/configs/Configs.env";

const reducers = combineReducers({
  theme: ThemeReducer,
  account: AccountReducer,
  toast: ToastPopupReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["toast"],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: (getDefaultMiddleware) =>
    !ENVS.REACT_APP_IS_PRODUCTION
      ? getDefaultMiddleware().concat(thunk).concat(logger)
      : getDefaultMiddleware().concat(thunk),
});

export const dispatch = store.dispatch;
export const state = store.getState();
export type IRootState = ReturnType<typeof store.getState>;
export type IAppDispatch = typeof store.dispatch;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
