import styled, { ITheme } from "styled-components";

export const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 101;
  opacity: 0;
  visibility: hidden;
  color: white;
  padding: 0 10px;
  .form {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    max-width: 532px;
    background: rgba(53, 52, 65, 0.5);
    backdrop-filter: blur(50px);
    padding: 56px 32px;
    ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          padding: 25px 25px;
    `}
    .container-icon {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .icon {
        align-self: flex-end;
        color: white;
        &.invisible {
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    .title {
      font-family: "Clash Display";
      font-weight: 600;
      font-size: 32px;
      margin-bottom: 16px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 24px;
    `}
    }
    .subtitle {
      font-family: "Poppins";
      font-weight: 400;
      font-size: 18px;
      color: #9ba5b1;
      margin-bottom: 30px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 16px;
    `}
    }
    .input {
      border-bottom: 1px solid rgba(155, 165, 177, 0.5);
      font-family: "Poppins";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      padding: 10px 0px;
      margin: 5px 0px;
      color: white;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 16px;
    `}
      &::placeholder {
        color: rgba(255, 255, 255, 1);
      }
      &.error {
        border-bottom: 1px solid red;
      }
      &:disabled {
        opacity: 0.5;
      }
    }
    .btn-loading-primary {
      margin-top: 48px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 18px !important;
    `}
    }
    .btn-loading-secondary {
      margin-top: 16px;
      ${({ theme }: { theme: ITheme }) => theme.mediaWidth.upToExtraSmall`
          font-size: 18px !important;
    `}
    }
  }
`;
