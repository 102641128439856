import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  z-index: 9999999999;
  position: fixed;
  top: 0;
  left: 0;
  transition: all 1s;
  overflow: visible;
  .progress-container {
    position: absolute;
    bottom: 20px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    span {
      transition: all 0.3s;
    }
    .progress {
      width: 100%;
      height: 4px;
      margin-right: 10px;
      background-color: rgba(155, 165, 177, 0.2);
      border-radius: 20px;
      overflow: hidden;
      position: relative;
      .overlay {
        height: 100%;
        background: linear-gradient(99.9deg, #3b67ff 3.81%, #c273f2 46.18%);
        transition: all 3s;
      }
    }
  }
`;
