import { useEffect } from "react";
import { toast } from "react-toastify";
import { ENVS } from "src/configs/Configs.env";
import { useChangeChain } from "src/services/hooks/useWalletProvider";
import { useWallet } from "use-wallet";
import { isMobile } from "react-device-detect";

export const ChangeChainProvider = ({ children }) => {
  const wallet = useWallet();
  const handleChangeChain = useChangeChain();
  useEffect(() => {
    if (isMobile) {
      wallet.connect("injected");
    }
  }, []);
  useEffect(() => {
    if (wallet.status === "connected") {
      if (wallet.chainId !== parseInt(ENVS.REACT_APP_BASE_CHAIN_ID)) {
        handleChangeChain(parseInt(ENVS.REACT_APP_BASE_CHAIN_ID));
        toast.info("Hmm! Look like you connected incompatible network");
      }
    } else if (wallet.status === "error") {
      if (wallet.error?.name === "ChainUnsupportedError") {
        handleChangeChain(parseInt(ENVS.REACT_APP_BASE_CHAIN_ID));
      }
      toast.error(wallet.error?.message);
    } // eslint-disable-next-line
}, [wallet?.status, wallet.error?.name, wallet?.chainId])
  return children;
};
