import { Close } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import React, { useCallback } from "react";
import Lottie from "react-lottie";
import animationData from "src/assets/authenticate/success-animation.json";
import animationData2 from "src/assets/authenticate/success-animation-2.json";
import animationDataFail from "src/assets/authenticate/fail-animation.json";
import { Wrapper } from "./ToastPopup.styled";
import { useSelector } from "react-redux";
import { removeToastPopup, selectToast, TOAST_STATUS } from "src/redux";
import { useAppDispatch } from "src/app/store";

const options1 = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const options2 = {
  loop: true,
  autoplay: true,
  animationData: animationData2,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const optionsError = {
  loop: true,
  autoplay: true,
  animationData: animationDataFail,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const ToastPopUp = () => {
  const { title, status, description } = useSelector(selectToast);
  const dispatch = useAppDispatch();
  const handleClose = useCallback(() => {
    dispatch(removeToastPopup());
  }, []);

  return (
    <Wrapper id="toast-popup">
      {status === TOAST_STATUS.SUCCESS && (
        <>
          <div onClick={handleClose} className="success left">
            <Lottie options={options2} />
          </div>
          <div onClick={handleClose} className="success right">
            <Lottie options={options2} />
          </div>
        </>
      )}
      <div onClick={handleClose} className="overlay"></div>
      <div className="popup">
        <IconButton onClick={handleClose} className="icon">
          <Close />
        </IconButton>
        {status === TOAST_STATUS.SUCCESS ? (
          <Lottie width={200} options={options1} />
        ) : (
          <Lottie width={200} options={optionsError} />
        )}
        <span className="title">{title}</span>
        <span className="subtitle">{description}</span>
      </div>
    </Wrapper>
  );
};

export default ToastPopUp;
