import { useWallet } from "use-wallet";
import { ethers } from "ethers";
import { toast } from "react-toastify";
// import { getWeb3 } from "../utils/getWeb3";
import ApproveABI from "src/constants/abis/ApproveERC20.json";

export const useWriteTransaction = () => {
  const { ethereum } = useWallet();

  const sendTransaction = async (
    contractAddress: string,
    abi: any,
    funcName: string,
    value: string,
    ...args
  ): Promise<any> => {
    try {
      const signer = new ethers.providers.Web3Provider(ethereum).getSigner();

      const contract = new ethers.Contract(contractAddress, abi, signer);
      const options = {
        value: ethers.utils.parseEther(value),
      };
      const tx = await contract.functions[funcName](...args, options);
      const isSuccess = await tx.wait();
      return isSuccess;
    } catch (err: any) {
      //TODO: handle error
      toast.error(err?.message || err);
    }
  };
  return { sendTransaction };
};

export const useRequestApproveERC20 = () => {
  const { sendTransaction } = useWriteTransaction();
  const requestApproveWithAmount = async (
    constractAddress: string,
    spenderAddress: string,
    amount: number
  ) => {
    const tx = await sendTransaction(
      constractAddress,
      ApproveABI,
      "0",
      spenderAddress,
      amount
    );
    return tx;
  };

  return { requestApproveWithAmount };
};
