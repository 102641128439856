export function isValidEmail(emailAdress?: string) {
  if (!emailAdress) {
    return false;
  }
  let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (emailAdress?.match(regexEmail)) {
    return true;
  } else {
    return false;
  }
}
