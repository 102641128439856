import BNBImage from "src/assets/wallet/bnb.svg";
import USDTImage from "src/assets/wallet/usdt.svg";
import BUSDImage from "src/assets/wallet/busd.svg";
import { ENVS } from "src/configs/Configs.env";
interface IToken {
  address: string;
  symbol: string;
  name: string;
  image: string;
}

export const tokenSupported: IToken[] = [
  {
    address: "0x0000000000000000000000000000000000000000",
    symbol: "BNB",
    name: "Binance",
    image: BNBImage,
  },
  {
    address: ENVS.REACT_APP_BUSD_ADDRESS,
    symbol: "BUSD",
    name: "Binance USD",
    image: BUSDImage,
  },
  {
    address: ENVS.REACT_APP_USDT_ADDRESS,
    symbol: "USDT",
    name: "Tether",
    image: USDTImage,
  },
];
